import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

import SCROLL_POSITION from './types';

type Props = {
  scrollState: number;
};

export default makeStyles<Props>()(
  ({ breakpoints, palette }, { scrollState }) => ({
    background: {
      backgroundColor: palette.primary[900],
    },
    carroussel: {
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      padding: `${rem(40)} ${rem(0)} ${rem(46)} ${rem(16)}`,
      margin: 'auto',
      [breakpoints.up('md')]: {
        padding: `${rem(60)} ${rem(0)} ${rem(46)} ${rem(40)}`,
      },
      [breakpoints.up('lg')]: {
        maxWidth: rem(1440),
        padding: `${rem(80)} ${rem(0)} ${rem(80)} ${rem(80)}`,
      },
    },
    container: {
      display: 'flex',
      width: '100%',
      gap: rem(26),
      zIndex: 1,
      paddingRight: rem(16),
      overflowX: 'auto',
      [breakpoints.up('md')]: {
        paddingRight: rem(40),
      },
      [breakpoints.up('lg')]: {
        paddingRight: rem(80),
      },
      // Hide scrollbar for Chrome, Safari and Opera
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      // Hide scrollbar for IE, Edge, and Firefox
      msOverflowStyle: 'none',
      scrollbarWidth: 'none',
    },
    buttonContainer: {
      marginTop: rem(20),
    },
    title: {
      marginBottom: rem(16),
      marginRight: rem(16),
      whiteSpace: 'pre-wrap',
      [breakpoints.up('md')]: {
        marginRight: rem(40),
      },
      [breakpoints.up('lg')]: {
        marginRight: rem(80),
      },
    },
    subTitle: {
      color: palette.neutral[500],
      marginBottom: rem(35),
      [breakpoints.up('md')]: {
        marginBottom: rem(40),
      },
      [breakpoints.up('lg')]: {
        marginBottom: rem(60),
      },
    },
    arrowBack: {
      color:
        scrollState === SCROLL_POSITION.LEFT
          ? palette.neutral[500]
          : palette.neutral[200],
    },
    arrowForward: {
      color:
        scrollState === SCROLL_POSITION.RIGHT
          ? palette.neutral[500]
          : palette.neutral[200],
    },
  })
);
