import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(() => ({
  imageContainer: {
    position: 'relative',
    flex: 1,
    borderRadius: rem(14),
    overflow: 'hidden',
    width: '100%',
  },
  image: {
    objectFit: 'cover',
    objectPosition: 'center center',
  },
}));
