import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({ palette: { common, supporting } }) => ({
  quoteStudent: {
    minWidth: rem(302),
    height: rem(375),
    overflow: 'hidden',
    borderRadius: rem(12),
    boxShadow: `0px 8px 12px -4px rgba(24, 24, 27, 0.08)`,
    position: 'relative',
    padding: rem(10),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  background: {
    background: `linear-gradient(0deg, rgba(10, 10, 10, 0.6), 
                                  rgba(10, 10, 10, 0.6)), 
                  linear-gradient(65.39deg, rgba(0, 0, 0, 0.6) 0%, 
                                  rgba(0, 0, 0, 0) 100%);`,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  cardSub: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  link: {
    color: supporting.blue.main,
  },
  description: {
    marginTop: rem(20),
  },
  animated: {
    width: '100%',
    backgroundColor: common.white,
    borderRadius: rem(12),
    zIndex: 2,
    padding: rem(20),
    overflow: 'hidden',
  },
  image: {
    objectFit: 'cover',
    objectPosition: 'center center',
  },
  imageHover: {
    mixBlendMode: 'multiply',
  },
}));
