import { FC, HTMLAttributes, PropsWithChildren } from 'react';
import Image from 'next/image';

import { getImageFromCloudImage } from '@/utils/cloudFrontLoader';

import useStyles from './styles';

export type PropsBackgroundImage = {
  url: string;
  alt: string;
  width?: number;
  height?: number;
} & HTMLAttributes<HTMLDivElement>;

const BackgroundImage: FC<PropsWithChildren<PropsBackgroundImage>> = ({
  url,
  alt,
  width,
  height,
  className,
  children,
}) => {
  const { classes, cx } = useStyles();
  return (
    <div className={cx(classes.imageContainer, className)}>
      <Image
        src={url}
        width={width}
        height={height}
        sizes="100vw"
        fill
        loader={getImageFromCloudImage}
        className={classes.image}
        alt={alt}
      />
      {children}
    </div>
  );
};

export default BackgroundImage;
