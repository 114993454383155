import { FC, HTMLAttributes, PropsWithChildren } from 'react';
import Image from 'next/image';

import Hidden from '@/generic/Hidden/Hidden';
import { getImageFromCloudImage } from '@/utils/cloudFrontLoader';

import useStyles from './styles';

type Props = {
  backgroundImage?: string;
  backgroundColor?: string;
};

const Hero: FC<PropsWithChildren<Props & HTMLAttributes<HTMLDivElement>>> = ({
  backgroundImage = null,
  backgroundColor = 'linear-gradient(0deg, rgba(24, 24, 27, 0.08), rgba(24, 24, 27, 0.08)), #1D4730',
  className,
  children,
}) => {
  const { classes, cx } = useStyles({ backgroundColor });

  return (
    <article className={classes.heroContainer}>
      <div className={cx(classes.hero, className)}>
        <Hidden variant="lg">
          {!backgroundImage ? (
            <div className={classes.background} />
          ) : (
            <Image
              src={backgroundImage}
              alt="Trouvez le prof particulier qui va lui donner confiance"
              sizes="100vw"
              fill
              className={classes.image}
              loader={getImageFromCloudImage}
              quality={100}
            />
          )}
        </Hidden>
        <Hidden isUp variant="lg">
          <div className={classes.background} />
        </Hidden>
        <div className={classes.gradient} />
        {children}
      </div>
    </article>
  );
};

export default Hero;
