import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({ palette, breakpoints }) => ({
  background: {
    backgroundColor: palette.common.white,
  },
  seo: {
    maxWidth: rem(1440),
    margin: 'auto',
    padding: `${rem(40)} ${rem(16)}`,
    [breakpoints.up('md')]: {
      padding: `${rem(60)} ${rem(40)}`,
    },
    [breakpoints.up('lg')]: {
      padding: rem(80),
    },
  },
  title: {
    marginBottom: rem(20),
  },
  grey: {
    color: palette.neutral[500],
  },
  subtitle: {
    color: palette.neutral[500],
    maxWidth: rem(760),
    marginBottom: rem(36),
    [breakpoints.up('md')]: {
      marginBottom: rem(40),
    },
    [breakpoints.up('lg')]: {
      marginBottom: rem(60),
    },
  },
  containerLinks: {
    display: 'flex',
    flexFlow: 'column',
    gap: rem(40),
  },
  cityContainer: {
    display: 'flex',
    gap: rem(32),
  },
  city: {
    flex: 1,
  },
}));
