import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

import noCssButton from '@/styles/noCssButton';

export default makeStyles<{ isOnOneColumn: boolean }>()(
  ({ palette, breakpoints }, { isOnOneColumn }) => ({
    seoContainer: {
      display: 'flex',
      flexFlow: 'column',
      borderRadius: rem(16),
      border: `2px solid ${palette.neutral[800]}`,
      backgroundColor: palette.common.white,
      padding: `${rem(28)} ${rem(32)} ${isOnOneColumn ? rem(32) : rem(12)}`,
      gap: rem(32),
      [breakpoints.up('lg')]: {
        gap: 0,
        flexFlow: isOnOneColumn ? 'column' : 'row',
      },
    },
    containerTitle: {
      display: 'flex',
      flexFlow: 'column',
      gap: rem(4),
      [breakpoints.up('lg')]: {
        padding: !isOnOneColumn ? rem(32) : 0,
        margin: 0,
      },
    },
    link: {
      color: palette.supporting.blue.main,
    },
    containerLink: {
      flex: 1,
      [breakpoints.up('lg')]: {
        padding: !isOnOneColumn ? rem(32) : `${rem(32)} 0 0`,
      },
    },
    noCssButton: {
      ...noCssButton,
      padding: 0,
      textAlign: 'center',
      [breakpoints.up('md')]: {
        textAlign: 'left',
      },
    },
  })
);
