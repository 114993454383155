import { FC, HTMLAttributes, PropsWithChildren, ReactElement } from 'react';
import { Typography } from '@mui/material';

import Button from '@/designSystem/Button/Button';
import { useIsMobile } from '@/utils/useResponsive';

import useStyles from './styles';

export type PropsFeatureCard = {
  title: string | ReactElement;
  subtitle: string | ReactElement;
  buttonLabel: string;
  onClick?: () => void;
};

const FeatureCard: FC<
  PropsWithChildren<PropsFeatureCard & HTMLAttributes<HTMLButtonElement>>
> = ({ title, subtitle, buttonLabel, onClick, className }) => {
  const { classes, cx } = useStyles();
  const isMobile = useIsMobile();
  return (
    <div className={cx(classes.featuresCard, className)}>
      <Typography variant="titleSm" component="h3" className={classes.title}>
        {title}
      </Typography>
      <Typography variant="bodyLg" component="p" className={classes.subtitle}>
        {subtitle}
      </Typography>
      <Button
        variant="primary"
        data-testid="feature-card-link"
        onClick={onClick}
        label={buttonLabel}
        isFullWidth={isMobile}
      />
    </div>
  );
};

export default FeatureCard;
