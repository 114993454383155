import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

import noCssLinkTag from '@/styles/noCssTag';

export default makeStyles()(({ palette, breakpoints, shadow }) => ({
  featuresCard: {
    zIndex: 2,
    display: 'flex',
    flexFlow: 'column',
    borderRadius: rem(12),
    backgroundColor: 'transparent',
    width: '100%',
    padding: `${rem(24)} 0 ${rem(20)}`,
    [breakpoints.up('md')]: {
      padding: rem(30),
      boxShadow: shadow.medium,
      backgroundColor: palette.common.white,
    },
    [breakpoints.up('lg')]: {
      maxWidth: rem(600),
      minWidth: rem(550), // NOTE: hard coded so nothing gets crushed while minimizing
      padding: `${rem(36)} ${rem(40)}`,
    },
  },
  title: {
    marginBottom: rem(24),
  },
  subtitle: {
    color: palette.neutral.main,
    marginBottom: rem(36),
    whiteSpace: 'pre-wrap',
  },
  link: {
    ...noCssLinkTag,
  },
}));
