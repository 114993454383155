import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

import maxWidthContainer from '@/styles/maxWidth';

export default makeStyles<{ backgroundColor: string }>()(
  ({ breakpoints }, { backgroundColor }) => ({
    heroContainer: {
      display: 'flex',
      alignItems: 'end',
      position: 'relative',
      width: '100%',
      padding: `${rem(40)} ${rem(16)}`,
      [breakpoints.up('md')]: {
        padding: rem(40),
      },
      [breakpoints.up('lg')]: {
        minHeight: rem(622),
        padding: rem(80),
        justifyContent: 'center',
      },
    },
    hero: {
      display: 'flex',
      width: '100%',
      ...maxWidthContainer,
    },
    background: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      background: backgroundColor,
      top: 0,
      left: 0,
    },
    gradient: {
      position: 'absolute',
      width: '100%',
      height: rem(608),
      background: 'linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 50%)',
      zIndex: 1,
      left: 0,
      bottom: 0,
    },
    image: {
      objectFit: 'cover',
    },
  })
);
