import { FC, HTMLAttributes, PropsWithChildren, ReactElement } from 'react';
import { Typography } from '@mui/material';

import SeoContainerLabel, {
  SeoContainerLabelProps,
} from '@Landing/Systems/SeoContainer/SeoContainerLabel/SeoContainerLabel';

import useStyles from './styles';

type Props = {
  seoTitle?: string | ReactElement;
  lableLink?: string | ReactElement;
  url?: string;
  isOnOneColumn?: boolean;
  onLinkClick?: () => void;
};

type Compound = {
  Label: FC<PropsWithChildren<SeoContainerLabelProps>>;
};

const SeoContainer: FC<
  PropsWithChildren<HTMLAttributes<HTMLDivElement> & Props>
> &
  Compound = ({
  seoTitle,
  lableLink,
  children,
  isOnOneColumn = false,
  onLinkClick,
  className,
}) => {
  const { classes, cx } = useStyles({ isOnOneColumn });

  const renderOneColumnLayout = () => (
    <section className={cx(classes.seoContainer, className)}>
      <ul className={classes.containerTitle}>
        {seoTitle && (
          <Typography variant="titleSm" component="h3">
            {seoTitle}
          </Typography>
        )}
        <div className={classes.containerLink}>{children}</div>
      </ul>
    </section>
  );

  const renderMultiColumnLayout = () => (
    <section className={cx(classes.seoContainer, className)}>
      <div className={classes.containerTitle}>
        {seoTitle && (
          <Typography variant="titleSm" component="h3">
            {seoTitle}
          </Typography>
        )}
        {!!lableLink && (
          <button
            type="button"
            onClick={onLinkClick}
            className={classes.noCssButton}
          >
            <Typography
              variant="bodyLgBold"
              component="span"
              className={classes.link}
            >
              {lableLink}
            </Typography>
          </button>
        )}
      </div>
      <div className={classes.containerLink}>{children}</div>
    </section>
  );

  return (
    <>{isOnOneColumn ? renderOneColumnLayout() : renderMultiColumnLayout()}</>
  );
};

SeoContainer.Label = SeoContainerLabel;

export default SeoContainer;
