import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'landing.seo.title',
    defaultMessage: `<grey>Des cours pour tous,</grey> au Collège, au Lycée, <enter></enter> en Prépa et dans le Supérieur.`,
  },
  subtitle: {
    id: 'landing.seo.subtitle',
    defaultMessage: `Les cours particuliers Les Sherpas, à domicile comme en ligne, sont pratiques et flexibles, et s’intègrent parfaitement à votre vie de famille.`,
  },
  subject: {
    id: 'landing.seo.subject',
    defaultMessage: 'Matières',
  },
  subjectLink: {
    id: 'landing.seo.subject.link',
    defaultMessage: 'Voir toutes les matières',
  },
  level: {
    id: 'landing.seo.level',
    defaultMessage: 'Niveaux',
  },
  levelLink: {
    id: 'landing.seo.level.link',
    defaultMessage: 'Voir tous les niveaux',
  },
  city: {
    id: 'landing.seo.city',
    defaultMessage: 'Villes',
  },
  cityLink: {
    id: 'landing.seo.city.link',
    defaultMessage: 'Voir toutes les villes',
  },
});
