import { FC, HTMLAttributes, PropsWithChildren } from 'react';

import BackgroundImage, {
  PropsBackgroundImage,
} from '@/components/DesignSystemLanding/Systems/Features/BackgroundImage/BackgroundImage';
import FeatureCard, {
  PropsFeatureCard,
} from '@/components/DesignSystemLanding/Systems/Features/FeatureCard/FeatureCard';

import useStyles, { BackgroundColor } from './styles';

type Props = {
  backgroundColor: BackgroundColor;
  isReverse?: boolean;
};

type Compounds = {
  FeatureCard: FC<PropsWithChildren<PropsFeatureCard>>;
  BackgroundImage: FC<PropsWithChildren<PropsBackgroundImage>>;
};

const Features: FC<PropsWithChildren<Props & HTMLAttributes<HTMLDivElement>>> &
  Compounds = ({
  backgroundColor,
  isReverse,
  children,
  className,
  ...rest
}) => {
  const { classes, cx } = useStyles({ backgroundColor, isReverse });
  return (
    <div className={cx(classes.features, className)} {...rest}>
      {children}
    </div>
  );
};

Features.FeatureCard = FeatureCard;
Features.BackgroundImage = BackgroundImage;

export default Features;
