import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'Landing.Carroussel.title',
    defaultMessage:
      "Nos élèves ont gagné <green>4 points</green> en moyenne.<enter></enter>Et ce n'est que le début !",
  },
  subTitle: {
    id: 'Landing.Carroussel.subTitle',
    defaultMessage: 'Ce que nos élèves pensent de nous :',
  },
  cardTitle1: {
    id: 'Landing.Carroussel.card1.title',
    defaultMessage: `Mélanie`,
  },
  cardDescription1: {
    id: 'Landing.Carroussel.card1.description',
    defaultMessage:
      'Avoir un mentor a fait une énorme différence. Mes notes ont monté, j’ai gagné en méthodes, et surtout en confiance en moi. Je me sens prête pour le Bac et l’université ! ',
  },
  cardTitle2: {
    id: 'Landing.Carroussel.card2.title',
    defaultMessage: `Camille`,
  },
  cardDescription2: {
    id: 'Landing.Carroussel.card2.description',
    defaultMessage:
      'Je vous recommande d’essayer Les Sherpas, la plateforme est facile à utiliser, et les professeurs particuliers sont vraiment super, avec des profils variés et des prix raisonnables. Les notes suivent !',
  },
  cardTitle3: {
    id: 'Landing.Carroussel.card3.title',
    defaultMessage: `Lucie`,
  },
  cardDescription3: {
    id: 'Landing.Carroussel.card3.description',
    defaultMessage:
      'Mathilde m’a redonné confiance en mes capacités et j’ai progressé grâce à ses conseils. De plus, habitant dans une petite ville, les cours en ligne permettent de trouver de bons professeurs.',
  },
  cardTitle4: {
    id: 'Landing.Carroussel.card4.title',
    defaultMessage: `Thomas`,
  },
  cardDescription4: {
    id: 'Landing.Carroussel.card4.description',
    defaultMessage:
      'Super flexibilité des horaires par rapport à mon emploi du temps, ajouté au fait d’avoir les cours chez soi sans perdre de temps dans les transports. ',
  },
  cardTitle5: {
    id: 'Landing.Carroussel.card5.title',
    defaultMessage: `Marc`,
  },
  cardDescription5: {
    id: 'Landing.Carroussel.card5.description',
    defaultMessage:
      'Initialement un peu sceptique sur les cours en ligne, cela marche vraiment bien : on peut tout faire depuis la plateforme. Notre fils a décroché son Bac avec 17 en maths. Ce n’était pas gagné, un grand merci ! ',
  },
});
