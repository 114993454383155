import { FC, PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';
import { SEOBlocks } from '@les-sherpas/sherpas-toolbox';
import { Typography } from '@mui/material';

import {
  LYON_LINKS,
  NICE_LINKS,
  PARIS_LINKS,
  TOULOUSE_LINKS,
} from '@/components/Landing/Seo/Seo.utils';
import EnterChunk from '@/components/ReactIntlChunks/EnterChunk';
import Hidden from '@/generic/Hidden/Hidden';
import useNavigation from '@/shared/Hooks/useNavigation';
import handleSendEventGTM from '@/utils/googleTagManagerHelper';
import SeoContainer from '@Landing/Systems/SeoContainer/SeoContainer';

import messages from './messages';

import useStyles from './styles';

type Props = SEOBlocks & {
  hasCities?: boolean;
};

const Seo: FC<PropsWithChildren<Props>> = ({
  cities,
  levels,
  subjects,
  hasCities = false,
}) => {
  const { classes } = useStyles();
  const { pushTo, urls } = useNavigation();

  return (
    <article className={classes.background}>
      <div className={classes.seo}>
        <Typography variant="titleLg" component="h2" className={classes.title}>
          <FormattedMessage
            {...messages.title}
            values={{
              grey: (chunks) => (
                <Typography
                  variant="titleLg"
                  component="span"
                  className={classes.grey}
                >
                  {chunks}
                </Typography>
              ),
              enter: EnterChunk,
            }}
          />
        </Typography>
        <Typography
          variant="body2xl"
          component="p"
          className={classes.subtitle}
        >
          <FormattedMessage {...messages.subtitle} />
        </Typography>
        <div className={classes.containerLinks}>
          {subjects.length !== 0 && (
            <SeoContainer
              seoTitle={<FormattedMessage {...messages.subject} />}
              lableLink={<FormattedMessage {...messages.subjectLink} />}
              url="/professeurs"
              onLinkClick={() => {
                pushTo(urls.search());
                handleSendEventGTM('see_all_subjects');
              }}
            >
              <SeoContainer.Label links={subjects} type="subject" />
            </SeoContainer>
          )}
          {levels.length !== 0 && (
            <SeoContainer
              seoTitle={<FormattedMessage {...messages.level} />}
              lableLink={<FormattedMessage {...messages.levelLink} />}
              url="/professeurs"
              onLinkClick={() => {
                pushTo(urls.search());
                handleSendEventGTM('see_all_levels');
              }}
            >
              <SeoContainer.Label links={levels} type="level" />
            </SeoContainer>
          )}
          {cities.length !== 0 && (
            <SeoContainer
              seoTitle={<FormattedMessage {...messages.city} />}
              lableLink={<FormattedMessage {...messages.cityLink} />}
              url="/professeurs"
              onLinkClick={() => {
                pushTo(urls.search());
                handleSendEventGTM('see_all_cities');
              }}
            >
              <SeoContainer.Label links={cities} type="location" />
            </SeoContainer>
          )}
          {hasCities && (
            <Hidden variant="lg">
              <div className={classes.cityContainer}>
                <SeoContainer
                  seoTitle="Paris"
                  className={classes.city}
                  isOnOneColumn
                >
                  <SeoContainer.Label
                    links={PARIS_LINKS}
                    type="subject"
                    isOnOneColumn
                  />
                </SeoContainer>
                <SeoContainer
                  seoTitle="Toulouse"
                  className={classes.city}
                  isOnOneColumn
                >
                  <SeoContainer.Label
                    links={TOULOUSE_LINKS}
                    type="subject"
                    isOnOneColumn
                  />
                </SeoContainer>
                <SeoContainer
                  seoTitle="Nice"
                  className={classes.city}
                  isOnOneColumn
                >
                  <SeoContainer.Label
                    links={NICE_LINKS}
                    type="subject"
                    isOnOneColumn
                  />
                </SeoContainer>
                <SeoContainer
                  seoTitle="Lyon"
                  className={classes.city}
                  isOnOneColumn
                >
                  <SeoContainer.Label
                    links={LYON_LINKS}
                    type="subject"
                    isOnOneColumn
                  />
                </SeoContainer>
              </div>
            </Hidden>
          )}
        </div>
      </div>
    </article>
  );
};

export default Seo;
