import { FC, PropsWithChildren, ReactElement, useRef, useState } from 'react';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ArrowForward from '@mui/icons-material/ArrowForward';
import { IconButton, Typography } from '@mui/material';

import QuoteStudent, {
  QuoteProps,
} from '@Landing/Systems/QuoteStudent/QuoteStudent';

import SCROLL_POSITION from './types';

import useStyles from './styles';

const SCROLL_WIDTH = 328;

type Compound = {
  QuoteStudent: QuoteProps;
};

type Props = {
  title: string | ReactElement;
  subtitle: string | ReactElement;
};

const Carroussel: FC<PropsWithChildren<Props>> & Compound = ({
  children,
  title,
  subtitle,
}) => {
  const containerRef = useRef(null);
  const [scrollState, setScrollState] = useState<number>(SCROLL_POSITION.LEFT);
  const { classes } = useStyles({
    scrollState,
  });

  const computeScrollState = (position: number) => {
    const element: HTMLDivElement = containerRef?.current;
    if (position <= 0) {
      return setScrollState(SCROLL_POSITION.LEFT);
    }
    if (position >= element.scrollWidth - element.offsetWidth - 100) {
      return setScrollState(SCROLL_POSITION.RIGHT);
    }

    return setScrollState(SCROLL_POSITION.MIDDLE);
  };

  const handleBackwards = () => {
    const element: HTMLDivElement = containerRef?.current;
    const nextPosition = Math.max(element.scrollLeft - SCROLL_WIDTH, 0);
    element?.scrollTo({
      behavior: 'smooth',
      left: nextPosition,
    });
    computeScrollState(nextPosition);
  };

  const handleForwards = () => {
    const element: HTMLDivElement = containerRef?.current;
    const nextPosition = Math.min(
      element.scrollLeft + SCROLL_WIDTH,
      element.scrollWidth - element.offsetWidth
    );
    element?.scrollTo({
      behavior: 'smooth',
      left: nextPosition,
    });
    computeScrollState(nextPosition);
  };

  return (
    <article className={classes.background}>
      <div className={classes.carroussel}>
        <Typography variant="titleLg" component="h2" className={classes.title}>
          {title}
        </Typography>
        <Typography
          variant="body2xl"
          component="p"
          className={classes.subTitle}
        >
          {subtitle}
        </Typography>
        <div className={classes.container} ref={containerRef}>
          {children}
        </div>
        <div className={classes.buttonContainer}>
          <IconButton
            onClick={handleBackwards}
            disabled={scrollState === SCROLL_POSITION.LEFT}
            size="large"
          >
            <ArrowBack fontSize="large" className={classes.arrowBack} />
          </IconButton>
          <IconButton
            onClick={handleForwards}
            disabled={scrollState === SCROLL_POSITION.RIGHT}
            size="large"
          >
            <ArrowForward fontSize="large" className={classes.arrowForward} />
          </IconButton>
        </div>
      </div>
    </article>
  );
};

Carroussel.QuoteStudent = QuoteStudent;

export default Carroussel;
