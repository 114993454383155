import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

import theme from '@/designSystem/theme';
import maxWidthContainer from '@/styles/maxWidth';

export type BackgroundColor = 'amber' | 'blue' | 'green' | 'red' | 'indigo';

type StyleProps = {
  backgroundColor: BackgroundColor;
  isReverse: boolean;
};

const getBackgroundColorFromVariant = (variant: BackgroundColor) => {
  switch (variant) {
    case 'indigo':
      return theme.palette.supporting.indigo[900];
    case 'red':
      return theme.palette.supporting.rose[900];
    case 'green':
      return theme.palette.supporting.green[900];
    case 'blue':
      return theme.palette.supporting.blue.light;
    case 'amber':
    default:
      return theme.palette.supporting.amber[900];
  }
};

export default makeStyles<StyleProps>()(
  ({ breakpoints }, { backgroundColor, isReverse }) => ({
    features: {
      overflow: 'hidden',
      backgroundColor: getBackgroundColorFromVariant(backgroundColor),
      display: 'flex',
      flexDirection: 'column',
      padding: rem(12),
      borderRadius: rem(20),
      width: '100%',
      gap: rem(20),
      flex: 1,
      position: 'relative',
      minWidth: rem(288),
      ...maxWidthContainer,
      minHeight: rem(500), // NOTE: default mode, recommended to always set backgroundimage dimensions
      [breakpoints.up('md')]: {
        padding: rem(20),
        gap: rem(50),
      },
      [breakpoints.up('lg')]: {
        minWidth: rem(1100), // NOTE: Prevent height auto adjustment
        padding: rem(28),
        flexDirection: isReverse ? 'row-reverse' : 'row',
      },
    },
  })
);
