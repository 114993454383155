import {
  FC,
  HTMLAttributes,
  PropsWithChildren,
  ReactElement,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { useMeasure } from 'react-use';
import { Rating, Typography } from '@mui/material';
import { animated, useSpring } from '@react-spring/web';
import Image from 'next/image';

import { getImageFromCloudImage } from '@/utils/cloudFrontLoader';

import messages from './messages';

import useStyles from './styles';

type Props = {
  imageUrl: string;
  description: string | ReactElement;
  rate: number;
  name: string | ReactElement;
  alt: string;
};

export type QuoteProps = FC<
  PropsWithChildren<Props & HTMLAttributes<HTMLDivElement>>
>;

const QuoteStudent: QuoteProps = ({
  imageUrl,
  rate,
  description,
  name,
  className,
  alt,
}) => {
  const [hover, setHover] = useState<boolean>(false);
  const { classes, cx } = useStyles();
  const [ref, { height }] = useMeasure();

  const expand = useSpring({
    config: { friction: 50, duration: 300 },
    height: hover ? height + 60 : height + 40,
    onStart: () => {
      if (hover) {
        window.dataLayer.push({
          event: 'read_review',
        });
      }
    },
  });

  const opacity = useSpring({ opacity: hover ? 1 : 0 });

  return (
    <div
      className={cx(classes.quoteStudent, className)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <animated.div className={classes.background} style={opacity} />
      <Image
        src={imageUrl}
        alt={alt}
        sizes="100vw"
        fill
        loader={getImageFromCloudImage}
        className={cx(classes.image, { [classes.imageHover]: hover })}
      />
      <animated.div style={expand} className={classes.animated}>
        <div ref={ref}>
          <Typography variant="title2Xs" component="span">
            {name}
          </Typography>
          <div className={classes.cardSub}>
            <Rating
              name="read-only"
              value={rate}
              readOnly
              precision={0.5}
              size="small"
              emptyIcon={null}
            />
            {!hover && (
              <Typography
                variant="bodySmBold"
                component="span"
                className={classes.link}
              >
                <FormattedMessage {...messages.readMore} />
              </Typography>
            )}
          </div>
          {hover && (
            <animated.div style={opacity}>
              <Typography
                variant="bodyLg"
                component="p"
                className={cx(classes.description)}
              >
                {description}
              </Typography>
            </animated.div>
          )}
        </div>
      </animated.div>
    </div>
  );
};

export default QuoteStudent;
